//blog-treding
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -30px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px;
  background-clip: padding-box;
}

//modals
.iq-modal {
  > .modal-dialog {
    max-width: 1400px;
  }
}

//file-manager dropzone
.file-manager-modal {
  .dzu-dropzone {
    min-height: 500px;
    overflow: hidden;
    border: 1px solid #8a92a6;
  }
}
.dark {
  .file-manager-modal {
    .dzu-dropzone {
      background-color: #151824;
    }
  }
}
.btn-upload {
  input[type='file']::file-selector-button {
    background-color: var(--bs-primary);
    border-radius: 0.25rem;
    box-shadow: none;
    color: white;
    border: 1px solid var(--bs-primary);
  }
  input[type='file']::file-selector-button:hover {
    background-color: rgba(var(--bs-primary), 0.9);
  }
}

//dark choices

.dark .choices[data-type*='select-one'] {
  .choices__input {
    background: #222738;
    border-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.dark .is-open .choices__list[aria-expanded] {
  border-color: rgba(#ffff, 0.1);
}

.custom-dropdown-focus:focus,
.custom-dropdown-focus:focus:not(:focus-visible) {
  color: white !important;
}

.custom-button-focus .btn:focus {
  color: white !important
}

.custom-dropdown-focus[aria-expanded="true"] {
  color: white !important;
}

.horizontal-line {
  height: 1px;
  flex-grow: 1;
  background-color: #eeeeee;
}

.vertical-line {
  width: 1px;
  background-color: #eeeeee;
}

.horizontal-line-negative-margin {
  height: 1px;
  background-color: #eeeeee;
  margin-left: -13px;
  margin-right: -13px;
}

// skeletion loader styles
.skeleton-loader {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: loading 1.2s infinite ease-in-out;
}

.skeleton-image {
  width: 100%;
}

.skeleton-title {
  width: 70%;
}

@keyframes loading {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f5f5f5;
  }
  100% {
    background-color: #e0e0e0;
  }
}

.dropdown-no-arrow::after {
  display: none;
}

.round-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

//Accordion
.accordion-button:not(.collapsed) {
  background-color: var(--bs-accordion-bg);
  color: black;
}

.nested-accordion .accordion-button:not(.collapsed),
.nested-accordion .accordion-item .accordion-body {
  background-color: #fff;
}

.nested-accordion .accordion-item {
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.long-text-column {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.long-text-column a {
  display: block;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.selected-row {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}

h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;
}

.fa-circle,
.fa-square,
.fa-chevron-up,
.fa-chevron-down,
.fa-trash-can {
  cursor: pointer !important;
}

.fa-grip-vertical {
  cursor: grab !important;
}

.no-arrow {
  border-radius: var(--bs-accordion-inner-border-radius);
  background-color: var(--bs-accordion-btn-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
  font-weight: 400;
  line-height: 1.2;
}
.no-arrow::after {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.bg-light-blue {
  background-color: rgba(var(--bs-primary-rgb), 0.1)
}

.bg-grey {
  background-color: #f9f9f9 !important
}

.generic-info-card-image {
  max-height: 200px;
  width: auto !important;
  margin: 0 auto;
}

.media-picker-preview-container {
  height: 60px;
  width: 100px;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1600px !important;
  }
}

.bg-badge-gray {
  background-color: #F9F9F9 !important;
  color: #8A92A6 !important;
  font-size: small;
  font-weight: 400;
}

.dropdown-menu {
  z-index: 1054123 !important;
}

.media-picker-preview-image {
  width: 80px;
  max-height: 80px;
  margin-right: 15px
}

.media-picker-preview-wrapper {
  min-height: 100px;
  padding: 10px
}

.list-no-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.participant-initials {
  width: 100px;
  height: 100px;
  background-color: #3b57f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.participant-initials-text {
  color: white;
  font-size: 40px;
}

.round-button {
  background-color: #3B82F6;
  border: none;
  border-radius: 50%;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.round-button:hover {
  background-color: #2563EB;
}

.round-button i {
  font-size: 24px;
}

.round-modal-close-button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0;
  flex-shrink: 0;
  flex-grow: 0;
}

.custom-accordion .accordion-item {
  border: none;
  background-color: white;
}

.custom-accordion-item {
  border: none;
}

.custom-accordion-header {
  background-color: white;
  border: none;
}

.custom-accordion-body {
  background-color: white;
}

.scrollable-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.custom-success-badge {
  background-color: #D5EBDF !important;
  color: #339966 !important;
  font-weight: normal !important;
}

.custom-danger-badge {
  background-color: #f3d8d4 !important;
  color: #a84332 !important;
  font-weight: normal !important;
}

.custom-primary-badge {
  background-color: #EBF1FF !important;
  color: #3366FF !important;
  font-weight: normal !important;
}

.progress-bar {
  background-color: black !important;
}

.ace_variable {
  color: #7587A6 !important;
}

.ace_string {
  color: #8F9D6A !important;
}

.calendar td {
  box-shadow: none !important;
}

.calendar .day-content.absence {
  background-color: rgba(192, 50, 33, 0.2) !important;
  color: black !important;
  box-shadow: none !important;
}

.calendar .day-content.attendance {
  background-color: rgba(26, 160, 83, 0.2) !important;
  color: black !important;
  box-shadow: none !important;
}

.calendar .day-content.deal-period {
  background-color: rgba(58, 87, 232, 0.1) !important;
  color: black !important;
  box-shadow: none !important;
}

.week-number-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.week-number {
  padding-left: 0px !important
}

.week-number-text {
  display: inline-block;
  font-size: 12px !important;
  padding: 2.5px;
  margin-right: 10px;
  font-family: monospace
}

.calendar table.month td.week-number {
  font-weight: 400 !important;
  color: #3A57E8;
  padding-right: 10px !important;
  padding-bottom: 5px !important;
  border-right: none !important
}

.calendar table.month th.week-number {
  font-weight: 400 !important;
  padding-right: 10px !important;
  font-size: 12px !important;
  padding: 5px !important;
  text-align: left;
  padding-left: 2.5px !important
}

.calendar table.month th.day-header {
  font-weight: 400 !important;
  font-size: 12px !important;
}

.week-icon {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-left: 5px;
}

.month-title {
  text-align: left !important;
  color: #8A92A6 !important;
  font-weight: 400;
  padding-left: 2.5px !important;
  color: #000 !important
}


.current-week {
  background-color: rgba(58, 87, 232, 0.2) !important;
  border-radius: 5px;
  font-size: 12px !important
}

.month-container {
  min-height: 250px !important;
}

.media-picker-preview-container {
  height: 60px;
  width: 100px;
  overflow: hidden;
}

.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 900;
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1600px !important;
  }
}

.bg-badge-gray {
  background-color: #f9f9f9 !important;
  color: #8a92a6 !important;
  font-size: small;
  font-weight: 400;
}

.dropdown-menu {
  z-index: 1054123 !important;
}

.media-picker-preview-image {
  width: 80px;
  max-height: 80px;
  margin-right: 15px;
}

.media-picker-preview-wrapper {
  min-height: 100px;
  padding: 10px;
}

.list-no-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.table-dropzone-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropzone-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.dropzone-overlay > div {
  pointer-events: all;
}

.no-border-dropdown .dropdown-toggle {
  border: none !important;
}

.no-border-dropdown .dropdown-menu {
  border: none !important;
}

.btn-muted {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}
.btn-muted:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-muted:disabled {
  background-color: #adb5bd;
  border-color: #adb5bd;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f0f0f0;
  color: #000;
}

.search-input .dropdown-toggle {
  background: #f9f9f9 !important;
  color: black !important;
  border: 1px solid #eee !important;
}

.search-input .dropdown-toggle::after {
  margin-left: 0.5rem;
}

.custom-search-input {
  background: #f9f9f9 !important;
  color: black;
  border: 1px solid #eee;
}

.custom-search-input:focus {
  background: #f9f9f9 !important;
  color: black;
  border: 1px solid #eee;
}

.custom-search-input::-webkit-search-cancel-button {
  cursor: pointer;
}

.center-image {
  height: 100%;
  width: auto !important;
  margin: 0 auto;
}

.no-border {
  border: none;
}

.list-group-item.active {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  border-color: rgba(var(--bs-primary-rgb), 0.1);
  color: var(--bs-primary);
}

.sidebar-base .nav-item .nav-link.static-item {
  margin-bottom: 0 !important;
}

.iq-main-menu {
  margin-top: 1rem !important;
}

.disabled-item {
  opacity: 0.5 !important;
  pointer-events: none;
}

.custom-switch .form-check-input {
  cursor: pointer;
}

.custom-switch label {
  cursor: pointer;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulsate {
  animation: pulsate 1s infinite;
}

.text-gray {
  color: #8A92A6 !important;
}

.text-light-black {
  color:   #232D42 !important; 
}

.sticky-item-text {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.button-group-custom {
  margin-bottom: 10px;
}

.button-group-custom .btn-primary {
  background-color: #e7f3ff;
  color: #0056b3;
  border: none;
}

.button-group-custom .btn-light {
  background-color: #f8f9fa;
  color: #6c757d;
  border: none;
}

.button-group-custom .btn-link {
  color: #007bff;
  text-decoration: none;
}

.dropdown-toggle-no-hover {
  background: none !important;
}

.dropdown-toggle-no-hover:hover {
  background: none !important;
}
.dropdown-toggle-no-hover:focus {
  background: none !important;
}
.dropdown-toggle-no-hover:focus-visible {
  background: none !important;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}
.apexcharts-datalabel-value {
  font-size: 21px;
  fill: #8a92a6;
}

.dashboard-dropdown-button .dropdown button {
  background-color: transparent !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
  color: rgba(0, 0, 0, 0.375) !important;
}

.custom-scrollbar {
  max-height: 300px;
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.no-caret::after {
  display: none !important;
}

.vertical-line {
  border-left: 1px solid #ccc;
  height: 20px;
  margin: 10px;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1000;
}

.overflow-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
}

.custom-scrollbar-x {
  overflow-x: auto;
  padding-bottom: 2px;
}

.custom-scrollbar-x::-webkit-scrollbar {
  height: 2px;
}

.custom-scrollbar-x::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}

.custom-scrollbar-x::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.fs-13 {
  font-size: 13px !important;
}

.checkbox-pointer input {
  cursor: pointer !important;
}

.checkbox-pointer label {
  cursor: pointer !important;
}

.sticky-left {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1000;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1000;
}

.custom-width-250 {
  width: 250px;
}

.custom-font-size-08 {
  font-size: 0.8rem;
}

.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

.sticky-right-reset-filter {
  position: sticky;
  right: 0;
  background-color: white; 
  z-index: 1000
}

.sticky-right {
  position: sticky;
  right: 0;
  background-color: white; 
  z-index: 5;
  overflow: visible; 
}

.sticky-right::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: #eee;
  z-index: 10;
}

.dropdown-menu-max-height {
  max-height: 200px;
}

.default-input-border {
  border: 1px solid #eee !important;
}

.pac-container {
  z-index: 2000 !important
}

.icon-position-address-autocomplete {
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: black;
  z-index: 2;
  position: absolute;
}

.edit-button {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

td:hover .edit-button {
  visibility: visible;
  opacity: 1;
}

.min-width-editable-table-cell {
  min-width: 200px;
}

.save-cancel-popup {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1);
  z-index: 1000;
}

.tooltip-overlay-quick-edit {
    padding: 1rem;
    border-radius: 0.375rem;
    position: absolute;
    z-index: 1000;
    width: 300px;
}

.tooltip-arrow-quick-edit {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #dee2e6;
    top: -12px;
    left: 20px;
}

.pending-change {
  color: orange !important;
}

.textarea-no-resize {
  resize: none;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.3) !important
}

textarea::placeholder {
  color: rgba(0, 0, 0, 0.3) !important
}

.editable-table-cell-placeholder:hover {
  background: rgba(0, 0, 0, 0.1) !important
}

.table-cell-wrap {
  max-width: 400px;
  overflow: hidden;
  white-space: 'nowrap';
  text-overflow: ellipsis;
}

.word-break {
  word-break: break-word;
  white-space: normal;
}

.sticky-col {
  position: sticky;
  left: 0;
  background-color: #fff !important;
  z-index: 5;
}

.sticky-col::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background-color: #eee;
  z-index: 10;
}

th.sticky-col {
  z-index: 5;
  background-color: #f9f9f9 !important;
}

.max-w-table-title {
  max-width: 500px !important
}

.solid-simple-border {
  border: 1px solid #eee !important;
}

.progress-bar-container {
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, rgba(26,160,83,0.4) 0%, rgba(26,160,83,0.6) 50%, rgba(26,160,83,1) 100%);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: relative;
}

.progress-bar-inner-container {
  position: relative;
  height: 100%;
}

.progress-bar {
  position: absolute;
  height: 100%;
}

.progress-bar-overlay {
  height: 100%;
  background-color: #ebeefd;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}

.progress-bar-vertical-line {
  position: absolute;
  top: -4px;
  height: 12px;
  width: 2px;
  background-color: rgba(26, 160, 83, 1);
  z-index: 2;
}

.abort-line {
  background-color: red;
}

.progress-bar-start-end-line {
  position: absolute;
  top: -4px;
  height: 12px;
  width: 2px;
  background-color: black;
}

.react-datepicker__close-icon::after {
  background-color: rgba(var(--bs-primary-rgb), 0.1) !important;
  color: var(--bs-primary) !important;
  font-size: 13px !important
}

.react-datepicker__day--outside-month {
  color: grey;
  opacity: 0.5;
}

.react-datepicker__week-number--keyboard-selected {
  background-color: inherit !important;
  color: #ccc !important
}

.w-100-px {
  width: 100px !important
}

.w-150-px {
  width: 150px !important
}

.max-w-100 {
  max-width: 100% !important
}

.w-40-px {
  width: 40px !important;
}

.participant-initials {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.smiley-icon-container {
  position: absolute;
  top: 90%;
  left: 90%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  width: 30%;
  height: 30%;
  padding: 30%;
}

.activity-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white
}

.resize-none {
  resize: none !important
}

.border-radius-comment {
  border-radius: 25px;
}

.smiley-picker-item {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  border-radius: 50%;
  transition: all 0.3s ease;
  cursor: pointer;
  color: inherit;
}

.smiley-picker-item.selected {
  background-color: var(--selected-bg-color, #4caf50);
  color: #fff;
}

.smiley-picker-item:hover {
  border-color: #888;
}

.no-style-link {
  all: unset;
  display: inline;
  cursor: pointer;
}

.btn-outline-neutral {
  border: 1px solid #ddd !important;
  background-color: transparent !important;
  color: #333 !important;
  transition: all 0.2s ease-in-out !important;
}

.bgs-viewer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.bgs-viewer-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.text-wrap {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.fc-day-other {
  background-color: #f5f5f5;
  color: #9e9e9e;
}

.fc-day-other .fc-event {
  opacity: 0.5;
}

.fc-event .fc-event-title {
  color: #3A57E8 !important;
}

.fc-event-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

.fc-event {
  background-color: #EBEEFD;
  border: none !important;
  padding: 2px 5px;
}

.fc-daygrid-day-number {
  color: #232D42 !important;
}

.fc-weeknumber {
  color: #232D42 !important;
}

.fc-col-header-cell-cushion {
  color: #8A92A6 !important;
}

.fc .fc-multimonth {
  border: none
}

.monthly-calendar .fc-daygrid-day-frame {
  height: 200px;
}

.switch-option {
  flex: 1;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.switch-option:not(.active){
  background: none !important
}

.info-dialog {
  max-width: 400px;
  width: 100%;
  z-index: 1080;
}

.hold-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 1.5rem;
  color: #232D42;
  cursor: pointer;
  width: 220px;
  height: 100px;
  transition: background-color 0.3s ease-in-out;
  background-color: #f8f9fa;
  overflow: hidden;
}

.hold-button.idle {
  background-color: #f8f9fa;
}

.hold-button.success {
  background-color: #d4edda;
}

.hold-button.loading {
  background-color: #fff3cd;
}

.hold-button.error {
  background-color: #f8d7da;
}

.hold-button-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #e0e0e0;
  border-radius: 1.5rem;
  transition: width 0.05s linear;
}

.hold-button-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}

.hold-button-content.success {
  color: var(--bs-success);
}

.hold-button-content.error {
  color: var(--bs-danger);
}

.hold-button-content.loading {
  color: var(--bs-warning);
}

.font-size-12 {
  font-size: 12px;
}

.clock-icon-wrapper {
  display: none;
}

.fc-daygrid-day:hover .clock-icon-wrapper {
  display: inline-block;
}